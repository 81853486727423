import { NextPageWithLayout } from "../lib/types";
import { Flex } from "@chakra-ui/react";
import Link from "next/link";
import { useAppSelector } from "@/lib/hooks";

const Page404: NextPageWithLayout = () => {
  const accessToken = useAppSelector((state) => state.user.accessToken);

  return (
    <Flex
      h={"100vh"}
      w={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <p>
        {/* TODO: fix redirect on 404 */}
        <Link href={accessToken === "" ? "/login" : "/dashboard"}>
          Go back (404)
        </Link>
      </p>
    </Flex>
  );
};

export default Page404;
